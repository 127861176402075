import React from "react";
import Layout from "../../layout/Layout";
import SEO from "../../layout/Seo";
import AnnualMorbidityReport from "../../components/Admin/AnnualMorbidityReport";

export default () => (
  <Layout pageTitle="Annual Morbidity Report">
    <SEO title="Annual Morbidity Report" />
    <AnnualMorbidityReport />
  </Layout>
);
