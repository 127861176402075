export const getDiseaseLabels = data => {
  let labels = [];
  if (!!data) {
    data.map(object => {
      labels = [...labels, ...Object.keys(object).filter(key => key != "site")];
    });
  }
  return Array.from(new Set(labels)); // Why Set? Because I just need unique values
};

export const sortAMRData = unsortedData => {
  let unsortedCategories = [];
  let categoriesOrder = Object.keys(amrOrder);
  let sortedData = [];

  // Sort by category first

  unsortedData.map(disease => {
    unsortedCategories.push(disease.category);
  });

  categoriesOrder.map(category => {
    let index = unsortedCategories.findIndex(element => element === category);
    sortedData.push(unsortedData[index]);
  });

  // Sorted data now has sorted categories. Proceed with sorting clinical conditions
  sortedData.map(category => {
    let sortedConditions = [];
    let conditionsOrder = amrOrder[category.category];

    conditionsOrder.map(condition => {
      let index = category.conditions.findIndex(
        element => element.description === condition
      );
      sortedConditions.push(category.conditions[index]);
    });

    category.conditions = sortedConditions;
  });

  return sortedData;
};

// Considerations: If backend updates ClinicalCondition, this will need to change as it is a frontend solution

export const amrOrder = {
  Skin: [
    "Allergy",
    "Dermatoses",
    "Infection as folliculitis/abscess/paronychia",
    "Others",
  ],
  Head: ["Migraine headache", "Tension headache", "Others"],
  Eyes: [
    "Error of refraction",
    "Bacterial/Viral conjunctivitis",
    "Cataract",
    "Others",
  ],
  "Mouth and ENT": [
    "Herpes labiales/nasalis",
    "Otitis media/externa",
    "Deafness",
    "Meniere’s syndrome/vertigo",
    "Rhinitis/Colds",
    "Nasal polyps",
    "Sinusitis",
    "Tonsillopharyngitis",
    "Laryngitis",
    "Others",
  ],
  Respiratory: [
    "Bronchitis",
    "Bronchial asthma",
    "Pneumonia",
    "Tuberculosis",
    "Others",
  ],
  "Heart and Blood Vessel": [
    "Hypertension",
    "Hypotension",
    "Angina pectoris",
    "Myocardial infarction",
    "Others",
  ],
  Gastrointestinal: [
    "Gastroenteritis/Diarrhea",
    "Amoebiasis",
    "Gastritis/Hyperacidity",
    "Appendicitis",
    "Infectious hepatitis",
    "Liver cirrhosis",
    "Hepatic abscess",
    "Cancer (hepatic/gastric)",
    "Others",
  ],
  Genitourinary: ["Urinary tract infection", "Stones", "Cancer", "Others"],
  Reproductive: [
    "Dysmenorrhea",
    "Infection (cervicitis)",
    "Infection (vaginitis)",
    "Abortion (spontaneous)",
    "Abortion (threatened)",
    "Hyperemesis gravidarum",
    "Uterine tumors",
    "Cervical polyp/cancer",
    "Ovarian cyst/tumors",
    "Sexually transmitted disease",
    "Hernia (inguinal)",
    "Hernia (femoral)",
    "Cancer",
    "Others",
  ],
  "Neuromuscular/Skeletal/Joints": [
    "Peripheral neuritis",
    "Torticollis",
    "Arthritis",
    "Others",
  ],
  "Lymphatic and Circulatory": [
    "Anemia",
    "Leukemia",
    "Cerebrovascular accidents",
    "Lymphadenitis",
    "Lymphoma",
    "Others",
  ],
  Infectious: [
    "Influenza",
    "Typhoid/Paratyphoid fever",
    "Cholera",
    "Measles",
    "Mumps",
    "Tetanus",
    "Malaria",
    "Schistosomiasis",
    "Herpes zoster",
    "Chickenpox",
    "German measles",
    "Rabies",
    "Others",
  ],
  "Diseases Due to Physical Environment": [
    "Diseases due to abnormalities in temperature and humidity",
    "Diseases due to abnormalities in air pressure",
    "Poisoning/overdosage to chemicals",
    "Others",
  ],
};
