import React, { useState, useEffect, Fragment } from "react";
import Datalist from "../../Templates/Forms/Datalist";
import MorbidityCategory from "./MorbidityCategory";

import api from "../../../services/api";
import { sortAMRData } from "../utils";

const initialClinics = [
  {
    label: "All Clinics",
    value: "",
  },
];

const initialClients = [
  {
    label: "All Clients",
    value: "all",
  },
];

const NUMBER_OF_YEARS = new Date().getFullYear() - 2017 + 1;

const AnnualMorbidityReport = () => {
  const [options, setOptions] = useState({
    clients: initialClients,
    clinics: initialClinics,
  });
  const [selected, setSelected] = useState({
    client: options.clients[0],
    clinic: options.clinics[0],
  });

  const years = new Array(NUMBER_OF_YEARS)
    .fill(0)
    .map((_, index) => {
      return { value: 2017 + index, label: 2017 + index };
    })
    .reverse();

  const [year, setYear] = useState(years[0]);
  const [report, setReport] = useState([]);

  // This useEffect fetches the initial list of clients
  useEffect(() => {
    if (options.clients.length < 2)
      api.get(`clients/?is_active=True`).then(response => {
        let tempClients = initialClients;
        tempClients = tempClients.concat(
          response.data.results.map(item => ({
            label: item.name,
            value: item.code,
          }))
        );
        setOptions({ ...options, clients: tempClients });
      });
  }, [options.clients]);

  // This useEffect fetches the clinics list when the client dropdown value changes
  useEffect(() => {
    if (selected.client !== "all")
      api
        .get(`clinics/?client__code=${selected.client.value}&is_active=True`)
        .then(response => {
          let tempClinics = initialClinics;
          tempClinics = tempClinics.concat(
            response.data.results.map(item => ({
              label: item.name,
              value: item.id,
            }))
          );
          setOptions({ ...options, clinics: tempClinics });
        });
  }, [selected]);

  useEffect(() => {
    api
      .get(
        `reports/annual_morbidity/?client=${
          selected.client.value
        }&clinic=${selected.clinic.value || "all"}&year=${year.value}`
      )
      .then(response => setReport(sortAMRData(response.data)));
  }, [selected, year]);

  return (
    <Fragment>
      <div className="columns">
        <div className="column">
          <Datalist
            label="Client"
            hasLabel
            defaultValue={options.clients[0]}
            options={options.clients}
            value={selected.client}
            onChange={value => {
              setSelected({
                ...selected,
                client: value,
                clinic: initialClinics[0],
              });
            }}
          />
        </div>
        <div className="column">
          <Datalist
            label="Clinic"
            hasLabel
            defaultValue={options.clinics[0]}
            options={options.clinics}
            isDisabled={selected.client.value === "all"}
            value={selected.clinic}
            onChange={value => {
              setSelected({ ...selected, clinic: value });
            }}
          />
        </div>
        <div className="column">
          <Datalist
            label="Year"
            hasLabel
            defaultValue={year}
            options={years}
            value={year}
            onChange={value => {
              setYear(value);
            }}
          />
        </div>
      </div>

      <div className="my-4">
        <table className="table is-fullwidth is-hoverable">
          <tr>
            <th>Category</th>
            <th>Conditions</th>
            <th>Male</th>
            <th>Female</th>
            <th>Total</th>
          </tr>
          {report.map(category => (
            <MorbidityCategory values={category} />
          ))}
        </table>
      </div>
    </Fragment>
  );
};

export default AnnualMorbidityReport;
