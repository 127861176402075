import React, { Fragment } from "react";

const MorbidityCategory = ({ values }) => {
  const conditions = values.conditions.filter(condition => !!condition);
  const conditionCount = conditions ? conditions.length : 0;

  return (
    <Fragment>
      {conditions.map((condition, index) => {
        const conditionDescription = condition ? condition.description : "";
        const malePopulation = condition ? condition.male : "";
        const femalePopulation = condition ? condition.female : "";
        const totalPopulation = condition
          ? condition.male + condition.female
          : 0;

        return (
          <tr>
            {index === 0 && (
              <th width="30%" rowSpan={conditionCount}>
                {values.category}
              </th>
            )}
            <td>{conditionDescription}</td>
            <td className="has-text-right">{malePopulation}</td>
            <td className="has-text-right">{femalePopulation}</td>
            <td className="has-text-right">{totalPopulation}</td>
          </tr>
        );
      })}
    </Fragment>
  );
};

export default MorbidityCategory;
